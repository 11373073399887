import { Link as NavLink } from "react-router-dom";
import classNames from "classnames";
import arrowRightRed from "../assets/icons/arrow-right-red.svg";
import arrowRightWhite from "../assets/icons/arrow-right-white.svg";
import "../styles/Link.scss";

const Link = ({ blok, active }) => {
  const url = blok.url.url || `/${blok.url.cached_url}`;

  if (blok.type === "nav") {
    return (
      <NavLink
        to={url}
        className={classNames(
          "link",
          active && "active",
          blok.type,
          blok.showUnderline && "underline",
          blok.isSubNav && "sub-nav"
        )}
      >
        {blok.showArrow && (
          <img
            src={blok.arrowColor === "red" ? arrowRightRed : arrowRightWhite}
            alt="arrow right"
            className="arrow"
          />
        )}
        {blok.label}
      </NavLink>
    );
  } else {
    return (
      <a
        href={url}
        className={classNames(
          "link",
          active && "active",
          blok.type,
          blok.showUnderline && "underline",
          blok.isSubNav && "sub-nav"
        )}
        target={blok.target}
      >
        <img
          src={blok.arrowColor === "red" ? arrowRightRed : arrowRightWhite}
          alt="arrow right"
          className="arrow"
        />
        {blok.label}
      </a>
    );
  }
};

export default Link;
