import { StoryblokComponent } from "@storyblok/react";
import "../styles/Nav.scss";

const Nav = ({ blok }) => {
  return (
    <div className="nav-container">
      <div className="main-nav">
        <img className="logo" src={blok.logo.filename} alt="logo" />
        <div>
          {blok.mainNav.map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
        <div>
          {blok.mainNavSub.map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
      </div>
      <div className="sub-nav-container">
        <div className="sub-nav"></div>
      </div>
    </div>
  );
};

export default Nav;
