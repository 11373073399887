import { storyblokEditable } from "@storyblok/react";
import "../styles/Text.scss";
import classNames from "classnames";

const Text = ({ blok }) => {
  return (
    <p
      className={classNames("text", blok.type, blok.bold && "bold")}
      {...storyblokEditable(blok)}
    >
      {blok.text}
    </p>
  );
};

export default Text;
