import { useState } from "react";
import { StoryblokComponent } from "@storyblok/react";
import closeIcon from "../assets/icons/close-button-white.svg";
import "../styles/Banner.scss";

const Banner = ({ blok }) => {
  const [hideBanner, setHideBanner] = useState(false);

  if (hideBanner) return null;
  function toggleHideBanner() {
    setHideBanner(!hideBanner);
  }

  return (
    <div className="banner">
      <div className="content">
        {blok.content.map((blok) => {
          return <StoryblokComponent blok={blok} key={blok._uid} />;
        })}
      </div>
      <button className="close" onClick={toggleHideBanner}>
        <img src={closeIcon} alt="close" />
      </button>
    </div>
  );
};

export default Banner;
