import { storyblokEditable } from "@storyblok/react";
import "../styles/Button.scss";
import classNames from "classnames";
import arrowRightWhite from "../assets/icons/arrow-right-white.svg";

const Button = ({ blok }) => {
  return (
    <button
      className={classNames("button", blok.type)}
      {...storyblokEditable(blok)}
    >
      {blok.showArrow && (
        <img src={arrowRightWhite} alt="arrow right" className="arrow" />
      )}
      {blok.label}
    </button>
  );
};

export default Button;
