import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import {
  storyblokInit,
  apiPlugin,
  useStoryblok,
  StoryblokComponent,
} from "@storyblok/react";

import "./styles/colors.scss";
import "./styles/globals.scss";

import Page from "./layouts/Page";

import Button from "./components/Button";
import Text from "./components/Text";
import Header from "./components/Header";
import Link from "./components/Link";
import Space from "./components/Space";

import Nav from "./modules/Nav";
import Banner from "./modules/Banner";

const getIsProd = () => {
  const isProd =
    window.location.origin === "https://my-storyblok-demo.pages.dev/";

  const params = new URLSearchParams(window.location.search);

  const hasPublishedParam = params.has("_storyblok_published");
  const hasDraftParam = params.has("_storyblok");

  if (hasDraftParam) return false;

  return hasPublishedParam || isProd;
};

storyblokInit({
  accessToken: getIsProd()
    ? "pYGGR2UNkZtsPekTfh9M2gtt"
    : "NZYsw20SeYyxQqMKqfdaYAtt",
  apiOptions: {
    region: "us",
  },
  use: [apiPlugin],
  components: {
    // components
    button: Button,
    text: Text,
    header: Header,
    link: Link,
    space: Space,

    // modules
    nav: Nav,
    banner: Banner,

    //layouts
    page: Page,
  },
});

const slugOverride = (slug) => {
  if (slug === "/") {
    return "home";
  }
  return slug;
};

const Root = () => {
  const location = useLocation();

  const slug = location.pathname;
  const story = useStoryblok(slugOverride(slug), {
    version: getIsProd() ? "published" : "draft",
  });

  if (!story?.content) {
    return <div>Loading...</div>;
  }

  return <StoryblokComponent blok={story.content} />;
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <Root />,
    errorElement: (
      <div>
        <h1>Page Not Found</h1>
      </div>
    ),
  },
]);

createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
