import { storyblokEditable } from "@storyblok/react";
import "../styles/Header.scss";
import classNames from "classnames";

const Header = ({ blok }) => {
  const Component = blok.type;
  return (
    <Component
      className={classNames(
        "header",
        blok.isRedGradient && "red-gradient",
        blok.type
      )}
      {...storyblokEditable(blok)}
    >
      {blok.text}
    </Component>
  );
};

export default Header;
